import { Box, Container } from "@chakra-ui/react";

import Head from "next/head";
import Navbar from "./Navbar";

type Props = {
  children: JSX.Element | JSX.Element[];
  pageTitle: string;
};

const PageLayout = ({ children, pageTitle }: Props) => {
  return (
    <>
      <Head>
        <title>{`${pageTitle} | Again Again`}</title>
        <meta name="description" content={pageTitle} />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Navbar />
      <Box as="main" role="main">
        <Container maxW={"6xl"} py={12}>
          {children}
        </Container>
      </Box>
    </>
  );
};

export default PageLayout;
