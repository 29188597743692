import { Box, Flex, Text } from "@chakra-ui/react";

import Image from "next/image";
import Link from "next/link";

const Navbar = () => {
  const logoScale: number = 0.3;

  return (
    <Box as="header" bg="brand.green">
      <Flex>
        <Box ml="24px" pt="var(--chakra-sizes-4)" pb="var(--chakra-sizes-3)">
          <Text>
            <Link href="/" color="white">
              <a>
                <Image
                  loading="eager"
                  src="/aa-logo-text.svg"
                  alt="Again Again Logo"
                  width={250 * logoScale}
                  height={135 * logoScale}
                />
              </a>
            </Link>
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};

export default Navbar;
