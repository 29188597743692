import type { NextPage } from "next";
import PageLayout from "components/layout/PageLayout";

import { Box, Heading, Text, Link, Button } from "@chakra-ui/react";

const Home: NextPage = () => {
  return (
    <PageLayout pageTitle="Onboarding">
      <Box width="100%">
        <Heading size="lg">Kia ora!</Heading>
        <Text mt={5}>
          <Link href="https://againagain.co" color="teal">
            Visit our website
          </Link>{" "}
          if you&apos;re interested in joining the Again Again network.
        </Text>
      </Box>
    </PageLayout>
  );
};

export default Home;
